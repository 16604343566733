<template>
  <layout-single>
    <template #content>
      <v-container class="pl-8 pr-8">
        <v-row
          v-for="scope in evaluation"
          :key="scope.scope"
          ripple
          class="cursor-pointer"
        >
          <v-col align="left">
            <v-row id="tooltip">
              <v-col cols="9" style="padding-bottom: 0px !important;">
                <p class="text-p font-weight-regular mt-1 mb-3">
                  {{ $t(scope.title) }}
                </p>
              </v-col>

              <v-col
                v-if="scope.tooltip"
                style="text-align: right; padding-bottom: 0px !important;"
              >
                <v-tooltip v-model="scope.showTooltip" left>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="mb-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="scope.showTooltip = !scope.showTooltip"
                    >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t(scope.tooltip)}}</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <evaluation-inputs
              v-if="scope"
              ref="evaluation"
              :inputs="scope.inputs"
              :values="event.results"
            />

          </v-col>
        </v-row>

       <v-row class="ma-0 pa-0">
         <v-col class="ma-0 pa-0">
             <v-list-item-content>
               <v-btn
                 elevation="0"
                 color="primary"
                 class="block"
                 :large="$vuetify.breakpoint.name !== 'xs'"
                 :class="{ 'blue white--text': true }"
                 @click="saveResults"
               >
                 {{ $t('Save') }}
               </v-btn>

               <v-btn
                 style="margin-top: 20px;"
                 elevation="0"
                 color="default"
                 :large="$vuetify.breakpoint.name !== 'xs'"
                 @click="onClose"
               >
                 {{ $t('Close') }}
               </v-btn>
             </v-list-item-content>
         </v-col>
       </v-row>
      </v-container>
    </template>
  </layout-single>
</template>

<script>
import LayoutSingle from '@/components/layout-single'
import EvaluationInputs from '@/components/evaluation/inputs.vue'

export default {
  name: 'EventEvaluation',

  components: {
    LayoutSingle,
    EvaluationInputs
  },

  data: () => ({
    showModal: false,
    selectedScope: 0,
    rawCompetitor: {},
    evaluation: []
  }),

  computed: {
    event () {
      return this.$store.state.rx.events.find(
        e => e.id === this.$route.params.eventId
      )
    },

    test () {
      return this.$store.state.rx.tests.find(
        t => t.type === this.event.type
      )
    }
  },

  mounted () {
    if (!this.event) {
      this.$router.push('/event').catch(() => {})
    }

    this.$store.commit('setNavigation', {
      show: true,
      title: 'Dog\'s evaluation',
      to: `/event/${this.$route.params.eventId}`
    })

    this.evaluation = this.test.evaluation.event.map(e => ({
      ...e,
      selectedValue: '',
      showTooltip: false
    }))
  },

  methods: {
    onClose () {
      this.redirect(`/event/${this.$route.params.eventId}`)
    },

    async saveResults () {
      if (!this.$refs.evaluation || this.$refs.evaluation.length === 0) {
        this.onClose()
        return
      }

      const results = this.$refs.evaluation.map(e => e.results()).flat()

      if (!results.length) {
        return
      }

      try {
        await this.event.atomicUpdate((reg) => {
          reg.results = results
          return reg
        })
      } catch (err) {
        console.log(err)
      }

      this.onClose()
    }
  }
}
</script>

<style lang="scss">
  .evaluation {
    .__view {
      display: flex !important;
      flex-direction: column;
      align-items: center;
    }
  }

  .points-input {
    .v-input__slot::before {
      border-color: transparent !important;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
